<template>
  <div id="landing-page">
    <div :class="['hero-section', 'section']">
      <div class="hero-content">
        <div class="hero-text">
          <h1>
            <i18next :translation="$t('LandingPage.heroTitle')">
              <template #highlight>
                <span class="highlight">{{ $t('LandingPage.heroTitleHighlight') }}</span>
              </template>
            </i18next>
          </h1>
          <p>{{ $t('LandingPage.intro0') }}</p>
          <p>{{ $t('LandingPage.intro1') }}</p>
          <p>{{ $t('LandingPage.intro2') }}</p>
          <div class="feature-highlights">
            <transition appear enter-active-class="animated fadeInUpSmall">
              <q-card style="display: flex; flex-direction: column">
                <q-card-section class="feature-icon-section justify-start">
                  <img src="../images/crown-icon.svg" />
                </q-card-section>
                <q-card-section style="font-size: 1.1em; color: #595959">{{ $t('LandingPage.tiedonhallintaa') }}</q-card-section>
                <q-card-actions style="margin-top: auto">
                  <q-btn color="blue" style="text-transform: initial" flat @click="onNavigationClick('tiedonhallinta')">{{ $t('LandingPage.readmore') }}</q-btn>
                </q-card-actions>
              </q-card>
            </transition>
            <transition appear enter-active-class="animated fadeInUpSmall">
              <q-card style="animation-delay: 100ms; display: flex; flex-direction: column">
                <q-card-section class="feature-icon-section">
                  <img src="../images/file-lock.svg" />
                </q-card-section>
                <q-card-section style="font-size: 1.1em; color: #595959">{{ $t('LandingPage.tietoturvaa') }}</q-card-section>
                <q-card-actions style="margin-top: auto">
                  <q-btn color="blue" style="text-transform: initial" flat @click="onNavigationClick('tietoturva')">{{ $t('LandingPage.readmore') }}</q-btn>
                </q-card-actions>
              </q-card>
            </transition>
            <transition appear enter-active-class="animated fadeInUpSmall">
              <q-card style="animation-delay: 200ms; display: flex; flex-direction: column">
                <q-card-section class="feature-icon-section">
                  <img src="../images/share-icon.svg" />
                </q-card-section>
                <q-card-section style="font-size: 1.1em; color: #595959">{{ $t('LandingPage.monipuolisuutta') }}</q-card-section>
                <q-card-actions style="margin-top: auto">
                  <q-btn color="blue" style="text-transform: initial" flat @click="onNavigationClick('monipuolisuus')">{{ $t('LandingPage.readmore') }}</q-btn>
                </q-card-actions>
              </q-card>
            </transition>
          </div>
        </div>
        <div class="hero-image">
          <q-card>
            <q-card-section>
              <h4>{{ $t('LandingPage.login') }}</h4>
              <div class="login-methods">
                <q-card :class="{ disabled: disableButtons }" @click="loginWebauthn">
                  <q-card-section>
                    <img src="../images/cryptokey-icon.svg" />
                  </q-card-section>
                  <q-card-section style="font-weight: 600"> {{ $t('LandingPage.login-yubikey') }}<br /> </q-card-section>
                </q-card>
                <q-card v-if="!$q.platform.is.mobile" @click="loginMobile">
                  <q-card-section>
                    <img src="../images/mobile-icon.svg" />
                  </q-card-section>
                  <q-card-section style="font-weight: 600"> {{ $t('LandingPage.login-mobile') }}<br /> </q-card-section>
                </q-card>
              </div>
            </q-card-section>
          </q-card>
          <q-card style="margin-top: 2em">
            <q-card-section style="text-align: center">
              <h4>{{ $t('LandingPage.registerquestion') }}</h4>
              <q-btn
                :disable="disableButtons"
                :label="$t('LandingPage.register')"
                rounded
                style="
                  background: linear-gradient(to bottom, #00d0f1, #048ed3);
                  color: white;
                  font-weight: 600;
                  height: 3.5em;
                  text-transform: none;
                  padding: 0 3em;
                "
                :to="{ path: '/registration' }"
              />
            </q-card-section>
          </q-card>
          <q-card style="margin-top: 2em">
            <q-card-section style="text-align: center">
              <p style="font-size: 1.1em; color: #595959">{{ $t('LandingPage.news') }}</p>
            </q-card-section>
          </q-card>
        </div>
      </div>
      <div class="privacy-statement-link-container">
        <a :href="privacyStatementUrl" class="privacy-statement-link">Tietosuojaseloste</a>
      </div>
      <q-separator color="black" />
    </div>
    <div class="infopage-header">
      <h5>{{ $t('LandingPage.motivation0') }}</h5>
      <h5>{{ $t('LandingPage.motivation1') }}</h5>
    </div>
    <div class="section">
      <div class="section-content">
        <div style="display: flex; flex-direction: column">
          <img src="../images/crown-icon.svg" style="max-width: 60px; margin-right: 15px" />
        </div>
        <div style="display: flex; flex-direction: column">
          <h5 :ref="bindSectionHeaderRef('tiedonhallinta')" class="infopage-subheader">{{ $t('LandingPage.topic-tiedonhallinta') }}</h5>
          <div v-for="i in 8" :key="i">
            <div>
              <p>{{ $t(`LandingPage.tiedonhallinta-infotext${i - 1}`) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="section-content">
        <div style="display: flex; flex-direction: column">
          <img src="../images/file-lock.svg" style="max-width: 60px; margin-right: 15px" />
        </div>
        <div style="display: flex; flex-direction: column">
          <h5 :ref="bindSectionHeaderRef('tietoturva')" class="infopage-subheader">{{ $t('LandingPage.topic-tietoturva') }}</h5>
          <div v-for="i in 5" :key="i">
            <div>
              <p>{{ $t(`LandingPage.tietoturva-infotext${i - 1}`) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="section-content">
        <div style="display: flex; flex-direction: column">
          <img src="../images/share-icon.svg" style="max-width: 60px; margin-right: 15px" />
        </div>
        <div style="display: flex; flex-direction: column">
          <h5 :ref="bindSectionHeaderRef('monipuolisuus')" class="infopage-subheader">{{ $t('LandingPage.topic-monipuolisuus') }}</h5>
          <div v-for="i in 9" :key="i">
            <div>
              <p>{{ $t(`LandingPage.monipuolisuus-infotext${i - 1}`) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="infopage-footer">
      <h5>{{ $t('LandingPage.footer0') }}</h5>
      <h5>{{ $t('LandingPage.footer1') }}</h5>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

import { Notification, NotificationLevel } from '@/classes/Notification'
import User, { UserJSON } from '@/classes/User'
import MobileAuthenticationDialog from '@/components/MobileAuthenticationDialog.vue'
import { apiFetchJSON, logout } from '@/utils/api-functions'
import { getAesWrappingKeyFromCredential, storeAuthIDToIndexedDB, webAuthnLogin } from '@/utils/crypt-utils'
import Imports from '@/utils/Imports'

@Component({})
export default class LandingPage extends Vue {
  sectionRefs: Record<string, Element> = {}
  disableButtons = false

  privacyStatementUrl = new URL('../../assets/privacy-statement-fi.pdf', import.meta.url).href

  get production() {
    return import.meta.env.MODE !== 'development'
  }

  bindSectionHeaderRef = (name: string) => (el: Element) => (this.sectionRefs[name] = el)

  onNavigationClick(name: string) {
    this.sectionRefs[name].scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  }

  async loginMobile(): Promise<void> {
    const dialog = this.$q.dialog({
      component: MobileAuthenticationDialog
    })

    dialog.onOk(({ key, authenticatorId }) => this.loginUser(authenticatorId, key, true))
  }

  async loginWebauthn(): Promise<void> {
    this.disableButtons = true
    // TODO: Hae backendistä sessioon tallennettava random token, jota käytetään WebAuthn challengena
    const credential = await webAuthnLogin().catch(error => {
      console.error(error)
      // Näytetään käyttäjälle geneerinen virheilmoitus seuraavassa ehtolauseessa
    })
    if (!credential) {
      this.notify(new Notification(NotificationLevel.WARNING, this.$t('LandingPage.securityDeviceLoginFailed')))
      this.disableButtons = false
      return
    }
    // @ts-ignore
    if (!credential?.getClientExtensionResults()?.prf?.results?.first && !credential?.getClientExtensionResults()?.largeBlob?.blob) {
      this.notify(new Notification(NotificationLevel.WARNING, this.$t('WebAuthnRegister.failed'), this.$t('WebAuthnRegister.noPrfOrLargeBlob')))
      this.disableButtons = false
      return
    }

    const key = await getAesWrappingKeyFromCredential(credential)

    await this.loginUser(credential.id, key)
  }

  async loginPrompt(userJSON: UserJSON) {
    return new Promise<void>((resolve, reject) => {
      const dialog = this.$q.dialog({
        title: this.$t('MobileAuthenticationDialog.confirmationDialog.title'),
        message: this.$t('MobileAuthenticationDialog.confirmationDialog.message', { name: userJSON.attributes.full_name }),
        cancel: this.$t('MobileAuthenticationDialog.confirmationDialog.cancel'),
        ok: this.$t('MobileAuthenticationDialog.confirmationDialog.ok')
      })

      dialog.onOk(() => resolve())

      dialog.onCancel(async () => {
        await logout()
        this.notify(new Notification(NotificationLevel.INFO, this.$t('MobileAuthenticationDialog.loginCancelledNotification')))
        reject(new Error('User cancelled login.'))
      })
    })
  }

  async loginUser(authenticatorId: string, key: CryptoKey, prompt: boolean = false) {
    console.log('LOGIN USER', authenticatorId, key, prompt)

    try {
      const userJSON = await apiFetchJSON<UserJSON>(`login`, {
        method: 'POST',
        body: JSON.stringify({ username: authenticatorId, password: '' })
      })

      let allowLogin = true

      if (prompt) {
        try {
          await this.loginPrompt(userJSON)
        } catch (err) {
          allowLogin = false
        }
      }

      if (allowLogin) {
        await this.afterLogin(userJSON, authenticatorId, key)
      }
    } catch (error) {
      this.disableButtons = false
      if (!this.$store.lastFetchReturnedError) {
        // Jos login-kutsun mukana ei tullut viestiä, näytetään geneerinen kirjautuminen epäonnistui -viesti
        this.notify(new Notification(NotificationLevel.WARNING, this.$t('LandingPage.loginFailed.title'), this.$t('LandingPage.loginFailed.generic')))
        // Heitetään muut virheet eteenpäin, jotta yleinen virheenkäsittely esittää tapahtuneen virheen käyttäjälle
      } else throw error
    }
  }

  /** Puretaan käyttäjän avaimet ja ohjataan eteenpäin */
  async afterLogin(userJSON: UserJSON, authenticatorId: string, key: CryptoKey): Promise<void> {
    this.$store.$patch({ csrfToken: userJSON.csrfToken })
    // const user = new User(userJSON, await getAesWrappingKeyFromCredential(credential), credential)
    const user = new User(userJSON, key, { id: authenticatorId } as Credential)
    this.$store.$patch({ user })
    storeAuthIDToIndexedDB(authenticatorId)
    this.loginRouteChecker()
  }

  loginRouteChecker(): void {
    const redirectRoute = this.$store.loginRedirect
    if (redirectRoute !== null) {
      // Siirrytään polkuun jota käyttäjä yritti katsoa ennen kirjautumista
      this.$store.$patch({ loginRedirect: null })
      this.$router.replace(redirectRoute)
    } else {
      this.$router.replace('/safebox/listing')
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  beforeRouteEnter(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): void {
    if (Imports.store.user) {
      // Käyttäjä on jo kirjautunut, ohjataan juureen
      next('/')
    } else {
      next()
    }
  }
}
</script>

<style scoped lang="scss">
$icon-size: 6rem;
$border-radius: 0.75rem;

.registration-dialog {
  @media (min-width: 800px) {
    width: 500px;
  }

  .q-btn {
    padding: 5px;
  }
}

@keyframes fadeInUpSmall {
  from {
    opacity: 0;
    transform: translate3d(0, 33%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUpSmall {
  animation-name: fadeInUpSmall;
}

#landing-page {
  background-color: #eef1f4;

  .hero-section {
    min-height: calc(100vh - 8rem);
    display: flex;
    flex-direction: column;

    @media (max-width: 1200px) {
      flex-direction: column;
      align-items: center;
      height: auto;
    }

    color: #1a3144;

    .theme-selector {
      transition-duration: 200ms;
      transform: rotateZ(0deg);
      cursor: pointer;
    }

    .hero-content {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      gap: 5em;
      padding: 0 5em;

      @media (max-width: 1200px) {
        max-width: 55em;
        flex-direction: column;
        align-items: stretch;

        .feature-highlights {
          justify-content: center;
          flex-wrap: wrap;

          .q-card {
            flex: 1;
          }
        }
      }

      .hero-text {
        max-width: 40rem;

        h1 {
          font-size: 2.5rem;
          font-weight: bold;
          line-height: 1.2;
          letter-spacing: -0.8pt;
          hyphens: manual;

          @media (max-width: 1200px) {
            font-size: 2rem;
          }

          .highlight {
            background: linear-gradient(to right, #37d8f2, #008bd1);
            background-clip: text;
            color: transparent;
          }
        }

        .feature-highlights {
          display: flex;
          gap: 1em;
          margin-top: 3em;

          .q-card {
            width: 14em;
            min-width: 14em;
            max-width: 14em;
            min-height: calc(14em / 2 * 3);
            background-color: #f9fafc;
            border-radius: $border-radius;
            box-shadow: 0px 3px 6px #0000002b;

            .feature-icon-section {
              text-align: center;
              height: 10em;
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                width: $icon-size;
                height: $icon-size;
              }
            }
          }
        }

        p {
          margin-top: 2rem;
          font-size: 16px;
          line-height: 1.5;
          color: #595959;
        }
      }

      .hero-image {
        .q-card {
          border-radius: $border-radius;
          padding: 1em;
          background: #f9fafc;
          box-shadow: 0px 3px 6px #0000002b;
        }

        img {
          max-width: 40vw;
          max-height: 50em;
          width: auto;
          height: auto;
        }

        h4 {
          font-weight: 700;
          text-align: center;
          margin-bottom: 2rem;
          font-size: 2.5rem;
        }

        .login-methods {
          display: flex;
          gap: 1.5em;
          padding: 0 1em;

          .q-card {
            flex: 1;
            text-align: center;
            cursor: pointer;
            transition-duration: 150ms;
            background-color: white;

            &.disabled {
              cursor: not-allowed;
            }

            &:hover {
              transform: scale(1.02);
            }

            &:active {
              transform: scale(1.01);
            }

            img,
            .q-icon {
              $icon-scale: 1;
              width: calc($icon-size * $icon-scale);
              height: calc($icon-size * $icon-scale);
            }

            .coming-soon {
              color: rgba(0, 0, 0, 0.3);
              white-space: nowrap;
            }
          }
        }
      }
    }

    ul.hero-navigation {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;

      @media (max-width: 1200px) {
        display: none;
      }

      li {
        display: flex;
        font-size: 1.3rem;
        flex-direction: column;
        flex-grow: 1;
        align-items: center;
        gap: 0.33rem;
        padding: 1em;
        position: relative;
        cursor: pointer;
        z-index: 0;
        overflow: hidden;

        &::before {
          content: '';
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          height: 100%;
          background: rgba(0, 0, 0, 0.2);
          transition-duration: 200ms;
          z-index: -1;
        }

        &:hover::before {
          top: 0;
        }
      }
    }
  }

  .infopage-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 2em 5em;
    margin-top: 25px;
    text-align: center;

    h5 {
      color: #048ed3;
      font-size: 1.4rem;
    }
  }

  .infopage-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 50px;
    overflow-wrap: break-word;
    text-align: center;
    flex-wrap: wrap;
    padding-right: 5em;
    padding-left: 5em;

    h5 {
      color: #048ed3;
      font-size: 1.5rem;
    }
  }

  .infopage-subheader {
    font-size: 16px;
    font-weight: bold;
    color: #048ed3;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .section:not(.hero-section) {
    font-size: 16px;
    line-height: 1.5;
    position: relative;
    padding: 2em 5em;

    .section-content {
      margin: 0 auto;
      max-width: 80ch;
      display: flex;
      flex-direction: row;
    }

    p {
      color: #595959;
    }

    h1 {
      font-size: 2.5rem;
      font-weight: 600;
    }
  }

  .privacy-statement-link-container {
    text-align: center;
    margin-bottom: 1em;
    padding-top: 3em;

    a {
      display: inline;
      font-weight: 500;
      color: black;
    }
  }
}
</style>
