import EventEmitter from 'eventemitter2'
import { flatten } from 'lodash-es'
import { Cookies } from 'quasar'

import { ARCHIVE_CATALOG_PATH } from '@/classes/exporter'
import Table from '@/classes/Table'
import Tuple, { TupleJSON } from '@/classes/Tuple'
import Value, { RealValueType } from '@/classes/Value'
import { apiFetchJSON, insertTuple, uploadFile } from '@/utils/api-functions'
import { AsyncMap } from '@/utils/AsyncMap'
import Imports from '@/utils/Imports'
import { Mutex } from '@/utils/mutex'
import { apiPath } from '@/utils/paths'
import { createSafebox, setSafeboxCookie } from '@/utils/safebox'
import { TupleHierarchyVisitor, TupleVisitor } from '@/utils/TupleHierarchyVisitor'

export type ArchiveFileInformation = {
  encrypted: boolean
}

const TUPLE_STATUS_MESSAGES: Record<string, string> = {
  service_attachment: 'Ladataan liitettä...',
  safebox: 'Luodaan tietolokeroa...',
  service: 'Käsitellään tietokorttia...',
  note: 'Käsitellään muistilappua...'
}

export abstract class ArchiveReader {
  abstract stat(path: string): Promise<ArchiveFileInformation | null>
  abstract setPassword(password: string): Promise<void>
  abstract read(path: string): Promise<Blob>

  async readString(path: string) {
    const blob = await this.read(path)
    return blob.text()
  }

  async readJSON(path: string) {
    const content = await this.readString(path)
    return JSON.parse(content)
  }
}

/**
 * Palauttaa hierarkiassa ensimmäisen isäntä-{@link Tuple Tuplen} avainmerkkijonon, joka on tyyppiä {@link table}.
 *
 * @param tuple Tuple, jota käyteään haun lähtöpisteenä.
 * @param table Taulu, jonka tyyppistä tuplea etsitään.
 */
const getParentId = (tuple: Tuple, table: string) => {
  let current: Tuple | null = tuple

  while (current && current.tab.name !== table) current = current.parent

  return current?.getKeyString()
}

export type NameConflictResolution = { resolution: 'merge' } | { resolution: 'skip' } | { resolution: 'rename'; name: string }

/**
 * Luokka joka toteuttaa tietolokeroiden tuonnin arkistotiedostosta sisältöineen.
 */
export class Importer extends EventEmitter {
  /**
   * Funktio, jota kutsutaan, jos tuotavan tietolokeron kanssa saman niminen tietoloker on jo olemassa.
   *
   * @param safebox Tuotava tietolokero, jonka kanssa saman niminen tietolokero on jo olemassa.
   * @returns Uusi nimi tietolokerolle tai `null`, jos tietolokeron tuominen halutaan ohittaa.
   */
  public onNameConflict?: (safebox: Tuple) => Promise<NameConflictResolution>

  /**
   * Taulu `service_attachment`-tuplejen ID:itä vastaavien tiedostojen poluista.
   */
  private attachments: Record<string, string> = {}

  /**
   * {@link TupleHierarchyVisitor}-instannsi, jonka avulla tuotavan tietolokeron sisältö käydään läpi ja
   * tuodaan järjestelmään.
   */
  private visitor = new TupleHierarchyVisitor()

  /**
   * {@link ArchiveReader}-toteutus, jonka avulla arkiston sisältö luetaan.
   */
  private reader?: ArchiveReader

  /**
   * Tilastotaulu käsiteltyjen tuplejen määrästä ja niiden kokonaismäärästä tauluittain.
   */
  private tupleCounts: Record<string, { current: number; total: number }> = {}

  /**
   * Taulu, joka kuvaa arkistossa esiintyvistä tuple-ID:istä ID:isiin, jotka tuplet saivat,
   * kun ne tuotiin järjestelmään. Avaimina toimivat vanhat ID:t ja arvoina uudet ID:t.
   *
   * Taulu on asynkroninen, jolloin toisesta tuplesta riippuvaiset tuplet voivat jäädä odottamaan kunnes,
   * tarvittu tuple luodaan.
   *
   * @remark
   * Jos tuodussa tuple-hierarkiassa esiintyy syklejä tai tuple viittaa omaan lapsenlapseensa, tämä aiheuttaa
   * deadlockin. Jos tällaisia hierarkioita on tarpeen tuoda, täytyy suuri osa logiikasta kirjoittaa uudelleen,
   * käsitellen hierarkiaa puun sijasta suunnattuna verkkona.
   */
  private keyMap: AsyncMap<string, RealValueType> = new AsyncMap()

  constructor() {
    super()

    this.visitor.on('*', this.updateStatus)
    this.visitor.on('service_attachment', this.handleAttachment)
    this.visitor.on('*', this.updateReferences)
    this.visitor.on('safebox', this.handleSafebox)
    this.visitor.on('*', this.insertTuple)
  }

  /**
   * Luo tuplen, kirjaten sen vanhant ja uudet avaimet {@link keyMap}-tauluun.
   * Asettaa myös tietolokero-keksin vastaamaan tuplen tietolokeroa operaation ajaksi.
   */
  async _insertTuple(tuple: Tuple, insertFn: (tuple: Tuple) => Promise<Tuple> = insertTuple) {
    const oldKeys: Map<string, RealValueType> = new Map()

    Object.values(tuple.tab.attributes).forEach(attr => {
      if (attr.key && !attr.reference) {
        oldKeys.set(attr.name, tuple.values[attr.name].v)
        tuple.resetValue(attr)
      }
    })

    const safeboxId = getParentId(tuple, 'safebox')

    if (safeboxId) {
      setSafeboxCookie(safeboxId)
    }

    const inserted = await insertFn(tuple)

    oldKeys.forEach((oldKey, attr) => {
      this.setNewKey(tuple.tab.name, attr, oldKey, inserted.values[attr].v)
    })

    return inserted
  }

  /**
   * Vierailijafunktio, joka lisää tuplen, muttei sen lapsia, järjestelmään.
   * Funktio ohittaa tietolokeroiden käsittelyn, sillä {@link handleSafebox} niitä vastaavat tuplet.
   */
  insertTuple: TupleVisitor = async tuple => {
    if (tuple.tab.name === 'safebox') {
      return tuple
    }

    const children = tuple.children
    tuple.children = {}

    let parentChildren: null | Record<string, Tuple[]> = null

    if (tuple.parent) {
      // Varmistetaan ettei toJSON yritä serialisoida rinnakkaisia tupleja,
      // joita ei ole vielä käsitelty.
      parentChildren = tuple.parent.children
      tuple.parent.children = {}
    }

    const inserted = await this._insertTuple(tuple)

    if (tuple.parent && parentChildren) {
      tuple.parent.children = parentChildren
    }

    inserted.children = children
    flatten(Object.values(inserted.children)).forEach(child => (child.parent = inserted))

    return inserted
  }

  /**
   * Asettaa tuplen avain-attribuuttin vanhaa arvoa vastaavan uuden arvon {@link keyMap}-tauluun.
   */
  private setNewKey(table: string, attr: string, oldValue: RealValueType, newValue: RealValueType) {
    this.keyMap.set(`${table}-${attr}-${oldValue}`, newValue)
  }

  /**
   * Vierailijafunktio, joka emitoi `status`-eventin, joka vastaa käsittelyssä olevaa tuplen tyyppiä ja sisältää
   * tiedon siitä, kuika monta vastaavan tyyppistä tuplea on jo käsitelty ja kuinka monta niitä on yhteensä.
   */
  updateStatus: TupleVisitor = async (tuple, ctx, next) => {
    const { current, total } = this.tupleCounts[tuple.tab.name]
    const message = TUPLE_STATUS_MESSAGES[tuple.tab.name]

    if (message) {
      this.emit('status', `${message} (${current + 1}/${total})`)
    }

    const result = await next(tuple, ctx)
    this.incrementCounter(tuple.tab.name)
    return result
  }

  /**
   * Vierailijafunktio, joka korvaa tuplen viiteattribuutit {@link keyMap}-taulusta saaduilla uusilla arvoilla,
   * ennen kuin tuple annetaan eteenpäin muiden vierailijoiden käsiteltäväksi.
   *
   * Funktio korvaa myös viittaukset käyttäjään nykyisellä käyttäjällä.
   */
  updateReferences: TupleVisitor = async tuple => {
    for (const attr of Object.values(tuple.tab.attributes)) {
      if (attr.reference) {
        const { table, attribute } = attr.reference
        const value = tuple.values[attr.name].v

        if (table === 'account' && attribute === 'username') {
          tuple.setValue(attr.name, Imports.store.user!.name)
          continue
        }

        if (value === null || value === undefined) {
          tuple.resetValue(attr)
        } else {
          const mapped = await this.keyMap.get(`${table}-${attribute}-${value}`)
          tuple.setValue(attr.name, mapped)
        }
      }
    }
  }

  /**
   * Laskee hierarkiassa olevien tuplejen kokonaismäärät niiden tauluittain ja päivittää ne {@link tupleVounts}-tauluun.
   */
  private async countTuples(tuple: Tuple) {
    const visitor = new TupleHierarchyVisitor()

    visitor.on('*', tuple => {
      const name = tuple.tab.name

      if (!this.tupleCounts[name]) {
        this.tupleCounts[name] = { current: 0, total: 1 }
      } else {
        this.tupleCounts[name].total++
      }
    })

    await visitor.visit(tuple)
  }

  /**
   * Inkrementoi taulua vastaavaa tilastoa {@link tupleCounts}-taulussa.
   */
  private incrementCounter(name: string) {
    if (this.tupleCounts[name]) {
      this.tupleCounts[name].current++
    }
  }

  /**
   * Vierailijafunktio, joka purkaa liitteen sisällön arkistosta, lataa sen palveluun ja
   * sisällyttää viitteen ladattuun tiedostoon tuplen `file`-attribuuttiin.
   */
  handleAttachment: TupleVisitor = async attachment => {
    const attachmentPath = this.attachments[attachment.values.id.valueToString()]
    const blob = await this.reader!.read(attachmentPath)
    const filename = attachment.values.filename.valueToString()
    const file = new File([blob], filename)
    const attr = attachment.tab.attributes.file
    const { path } = await uploadFile(file, attr, attachment)
    attachment.values.file = new Value(attr, filename, filename, null, path)
  }

  safeboxMoutex = new Mutex()

  /**
   * Vierailijafunktio, joka luo tietolokeron, määrittäen tarvittavat avaimet ja oikeudet.
   *
   * Tietolokeroiden luominen vaatii erityiskäsittelyä, sillä niiden kanssa on atomisesti luotava tarvittavat
   * `safebox_access` ja `safebox_key` -tuplet. Lisäksi luodut tietolokeron avaimet täytyy sisällyttää session
   * tietoihin, jotta tietolokeron alaisten salattujen tuplejen salausoperaatiot onnistuisivat.
   */
  handleSafebox: TupleVisitor = async (safebox, context, next) => {
    const _handleSafebox: TupleVisitor = async (safebox, context, next) => {
      let create = true

      const existing = Imports.appStore.safeboxes.find((s: Tuple) => s.values.name.v === safebox.values.name.v)

      // Jos samanniminen tietolokero on jo olemassa, kysytään halutaanko
      // tietolokero uudelleennimetä vai sen tuominen ohittaa.
      if (existing) {
        if (this.onNameConflict) {
          const resolution = await this.onNameConflict(safebox)

          if (resolution.resolution === 'rename') {
            safebox.setValue('name', resolution.name)
            return _handleSafebox(safebox, context, next)
          } else if (resolution.resolution === 'merge') {
            create = false
          } else {
            return null
          }
        } else {
          return null
        }
      }

      const allChildren = safebox.children
      delete allChildren.safebox_key
      delete allChildren.safebox_access

      safebox.children = {}

      // Käytetään createSafebox-metodia tietolokeron luomiseen tai
      // dummy-metodia, joka palauttaa olemassa olevan tietolokeron, jos
      // näin käyttäjä halusi.
      const createFn = create ? createSafebox : async () => existing!

      // Olemassa olevat tietolokerot kierrätetään myös _insertTuple-metodin läpi
      // jotta niiden avaimet päivitetään oikein `keyMap`-tauluun.
      const inserted = await this._insertTuple(safebox, createFn)

      const logResult = await apiFetchJSON<{ id: string }>(`${apiPath}/log`, {
        method: 'POST',
        body: JSON.stringify({
          safebox: inserted.values.id.v,
          action: 'import'
        })
      })

      try {
        Cookies.set('digua_action_id', logResult.id)

        inserted.children = allChildren

        flatten(Object.values(inserted.children)).forEach(child => (child.parent = inserted))

        return await next(inserted)
      } finally {
        Cookies.remove('digua_action_id')
      }
    }

    return this.safeboxMoutex.with(async () => _handleSafebox(safebox, context, next))
  }

  /* private async removeHierarchyIds(tuple: Tuple): Promise<Tuple> {
    const visitor = new TupleHierarchyVisitor()

    visitor.on('*', this.removeIds)

    const result = await visitor.visit(tuple, undefined)

    return result!
  } */

  /**
   * Tuo yhden tietolokeron arkistosta luetusta tuple-hierarkiasta.
   */
  async importSafebox(safebox: Tuple) {
    // const woIds = await this.removeHierarchyIds(safebox)
    const processed = await this.visitor.visit(safebox)

    return processed
  }

  /**
   * Luo kaikki arkiston sisältämät tietolokerot ja palauttaa ne.
   */
  async importFrom(reader: ArchiveReader) {
    this.reader = reader
    const catalog: { safeboxes: TupleJSON[]; attachments: Record<string, string> } = await this.reader.readJSON(ARCHIVE_CATALOG_PATH)

    Object.assign(this.attachments, catalog.attachments)

    const safeboxTab = Table.getTable('safebox')

    this.emit('status', 'Tulkitaan arkistoa...')

    const safeboxTuples = await Promise.all(catalog.safeboxes.map(json => Tuple.fromJSON(json, safeboxTab)))

    await Promise.all(safeboxTuples.map(safebox => this.countTuples(safebox)))

    const results = await Promise.all(
      safeboxTuples.map(async safebox => {
        const result = await this.importSafebox(safebox)
        return result ? [result] : []
      })
    )

    return results.flat()
  }
}
