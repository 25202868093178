<script lang="ts">
import { LogoutView as KLogoutView } from '@kanto/views/LogoutView.vue'
import { Component } from 'vue-facing-decorator'

@Component
export default class LogoutView extends KLogoutView {
  beforeMount(): void {
    // Tyhjätään käyttäjän avaimet muistista, jotta kukaan ei voi niitä onkia myöhemmin selaimesta
    this.$store.user?.clearKeys()
    this.$store.user?.clearAuthID()
  }
}
</script>
